import React, { useState } from "react"
import Layout from "../components/layout"

export default function BYUAuth() {
  const [id, setId] = useState("")
  const [netId, setNetId] = useState("")
  const [processing, setProcessing] = useState(false)

  function handleIdSubmit(id) {
    if (processing) {
      return
    }

    setProcessing(true)

    fetch(`${process.env.GATSBY_FIREBASE_URI}/auth_byu_id`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ticket: id + "", netId: netId + "" }),
    })
      .then(res => res.json())
      .then(data => {
        const { authenticated, error } = data
        if (authenticated || error === "has taken survey") {
          window.location.replace(
            `${process.env.GATSBY_MATCHMAKER_URI}?ticket=${id}&school=BYU&netId=${netId}`
          )
        } else {
          alert("Verifying...please enter your ID and Net ID again to confirm.")
          setId("")
          setNetId("")
        }

        setProcessing(false)
      })
  }

  return (
    <Layout>
      <div className="h-screen justify-center flex items-center">
        <form
          className="flex flex-col items-center justify-center w-2/3 md:w-2/5 h-72 p-8 rounded-lg"
          style={{ backgroundColor: "#042d5e" }}
        >
          <p className="text-lg md:text-4xl text-normal text-white">
            Are you a BYU Student?
          </p>
          <label
            className="text-sm md:text-2xl text-normal text-white"
            htmlFor="byuid"
          >
            Please enter your BYU ID (e.g. XX-XXX-XXXX) and Net ID (e.g.
            johndoebyu123)
          </label>
          <input
            className="outline-black focus:border-white-300 text-center my-2"
            type="text"
            placeholder="Enter BYU ID here"
            id="byuid"
            value={id}
            onChange={e => setId(e.target.value)}
          ></input>
          <input
            className="outline-black focus:border-white-300 text-center my-4"
            type="text"
            placeholder="Enter netid here"
            id="netid"
            value={netId}
            onChange={e => setNetId(e.target.value)}
          ></input>
          <input
            type="button"
            value={processing ? "verifying..." : "verify"}
            className="px-2 text-white my-4 bg-blue-500"
            style={{ border: "2px solid #ffffff" }}
            onClick={() => handleIdSubmit(id)}
          ></input>
        </form>
      </div>
    </Layout>
  )
}
